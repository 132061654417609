export default [
  {
    value: "5",
    label: "총괄 책임자",
  },
  {
    value: "3",
    label: "관리자",
  },
  {
    value: "0",
    label: "일반",
  },
];
