import axios from "axios";
import { setInterceptors } from "./common/interceptors";

function createInstance() {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });

  return setInterceptors(instance);
}

function createInstanceWithToken() {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });
  return setInterceptors(instance);
}

function createInstanceAuth() {
  return axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/auth`,
  });
}

function createInstanceAuthWithToken() {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/auth`,
  });
  return setInterceptors(instance);
}
function createInstanceWithPdfConfig(data) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });

  return setInterceptors(instance);
}

export const instance = createInstance();
export const instanceToken = createInstanceWithToken();
export const instanceAuth = createInstanceAuth();
export const instanceAuthToken = createInstanceAuthWithToken();
export const instancePdf = createInstanceWithPdfConfig();
