//오더 페이지 왼쪽 오더 리스트 헤더
export default [
  {
    text: "접수",
    value: "date",
    width: "9%",
    align: "center",
    class: "tableHeaderBody",
    type: "date",
    sort: -1,
    dateType: "mmdd",
    filterable: true,
  },
  {
    text: "년도",
    value: "order_front_year",
    width: "2%",
    align: "center",
    show: true,
    sort: -1,
    class: "borderHeaderEmpty",
  },
  {
    text: "시즌",
    value: "order_front_season",
    width: "2%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
  },
  {
    text: "오더",
    value: "order",
    width: "10%",
    align: "center",
    class: "tableHeaderBody",
    sort: -1,
    filterable: true,
  },
  {
    text: "발주처",
    value: "client",
    width: "12%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "아이템",
    value: "item",
    width: "15%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "생산처",
    value: "productionName",
    width: "14%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "생산처 아이템 ",
    value: "productionItemName",
    width: "14%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "담당",
    value: "liaison",
    width: "7%",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
  },

  {
    text: "진행 사항",
    value: "progress",
    width: "6%",
    align: "center",
    class: "tableHeaderRigth",
    filterable: false,
  },
];
