export default {
  main: {
    paramName: "setMainParam",
    totalApiName: "CALL_ORDER_TOTAL_API",
    searchApiName: "CALL_ORDER_SEARCH_API",
    totalCommitName: "setMainTotal",
    searchCommitName: "setMainOrderList",
    infiniteCommitName: "setMainPageListInfinite",
    sortCommitName: "setMainSort",
    status: ["40"],
    pageType: "main",
  },
  order: {
    paramName: "setOrderParams",
    totalApiName: "CALL_ORDER_TOTAL_API",
    searchApiName: "CALL_ORDER_SEARCH_API",
    totalCommitName: "setOrderTotalCount",
    searchCommitName: "setOrderPageList",
    infiniteCommitName: "setOrderPageListInfinite",
    sortCommitName: "setOrderSort",
    status: ["20", "30", "40", "50", "90"],
    managerStatus: ["40"],
    pageType: "order",
  },
  production: {
    paramName: "setProductionParams",
    totalApiName: "CALL_ORDER_TOTAL_API",
    searchApiName: "CALL_ORDER_SEARCH_API",
    totalCommitName: "setProductionTotalCount",
    searchCommitName: "setProductionOrderList",
    infiniteCommitName: "setProductionPageListInfinite",
    sortCommitName: "setProductionSort",

    status: ["40"],
    pageType: "order",
  },
  purchasecalculate: {
    paramName: "setPurchaseCaculParam",
    totalApiName: "CALL_ORDER_TOTAL_API",
    searchApiName: "CALL_ORDER_SEARCH_API",
    totalCommitName: "setCalculateTotal",
    searchCommitName: "setCalculates",
    infiniteCommitName: "setCalculatesInfinite",
    sortCommitName: "setCalculatesSort",
    checkboxCommitName: "setPurCheckBox",
    status: ["20", "30", "40"],
    pageType: "caculate",
    type: "purchase",
  },
  purchasedeadline: {
    paramName: "setPurchaseEndParam",
    totalApiName: "CALL_ORDER_TOTAL_API",
    searchApiName: "CALL_ORDER_SEARCH_API",
    totalCommitName: "setCalculateTotal",
    searchCommitName: "setCalculateEnds",
    infiniteCommitName: "setCalculateEndsInfinite",
    sortCommitName: "setCalculateEndsSort",
    checkboxCommitName: "setEndPurCheckBox",
    status: ["00", "10"],
    pageType: "end",
    type: "purchase",
  },
  salescalculate: {
    paramName: "setSalesCaculParam",
    totalApiName: "CALL_ORDER_TOTAL_API",
    searchApiName: "CALL_ORDER_SEARCH_API",
    totalCommitName: "setCalculateTotal",
    searchCommitName: "setSalesCalculates",
    infiniteCommitName: "setSalesCalculatesInfinite",
    sortCommitName: "setSalesCalculatesSort",
    checkboxCommitName: "setSalesCheckBox",
    status: ["20", "30", "40"],
    pageType: "caculate",
    type: "sales",
  },
  salesdeadline: {
    paramName: "setSalesEndParam",
    totalApiName: "CALL_ORDER_TOTAL_API",
    searchApiName: "CALL_ORDER_SEARCH_API",
    totalCommitName: "setCalculateTotal",
    searchCommitName: "setSalesCalculateEnds",
    infiniteCommitName: "setSalesCalculateEndsInfinite",
    sortCommitName: "setSalesCalculateEndSort",
    checkboxCommitName: "setEndSalesCheckBox",
    status: ["00", "10"],
    pageType: "end",
    type: "sales",
  },
  purchaseVat: {
    paramName: "setRecivableParam",
    totalApiName: "CALL_RECEIVABLE_TOTAL_API",
    searchApiName: "CALL_RECEIVABLE_SEARCH_API",
    totalCommitName: "setRecivableTotal",
    searchCommitName: "setVatList",
    infiniteCommitName: "setVatListInfinite",
    sortCommitName: "setVatSort",
    kind: "purchase",
  },
  salesVat: {
    paramName: "setSalesRecivableParam",
    totalApiName: "CALL_RECEIVABLE_TOTAL_API",
    searchApiName: "CALL_RECEIVABLE_SEARCH_API",
    totalCommitName: "setRecivableTotal",
    searchCommitName: "setVatSalesList",
    infiniteCommitName: "setVatSalesListInfinite",
    sortCommitName: "setVatSalesSort",
    kind: "sales",
  },
  claim: {
    paramName: "setClaimParam",
    totalApiName: "CALL_CLAIM_TOTAL_API",
    searchApiName: "CALL_CLAIM_SEARCH_API",
    totalCommitName: "setClaimTotal",
    searchCommitName: "setClaimList",
    infiniteCommitName: "setClaimListInfinite",
    sortCommitName: "setClaimSort",
    ariseCheckboxCommitName: "setAriseAllChecked",
    passCheckboxCommitName: "setPassAllChecked",
    isEndCase: "false",
  },
  endClaim: {
    paramName: "setEndClaimParam",
    totalApiName: "CALL_CLAIM_TOTAL_API",
    searchApiName: "CALL_CLAIM_SEARCH_API",
    totalCommitName: "setClaimTotal",
    searchCommitName: "setEndClaimList",
    infiniteCommitName: "setEndClaimListInfinite",
    sortCommitName: "setEndClaimSort",
    ariseCheckboxCommitName: "setEndAriseAllChecked",
    passCheckboxCommitName: "setEndPassAllChecked",
    isEndCase: "true",
  },
  stock: {
    paramName: "setStockParam",
    totalApiName: "CALL_STOCK_TOTAL_API",
    searchApiName: "CALL_STOCK_SEARCH_API",
    totalCommitName: "setStockTotal",
    searchCommitName: "setStockTableList",
    infiniteCommitName: "setStockListInfinite",
    sortCommitName: "setStockSort",
    checkboxCommitName: "setStockCheckbox",
  },
};
