import { instance } from "./index";
function getPaymentList(data) {
  return instance.get(`pay/${data.company}/${data.status}/${data.sort}`);
}
function getAdminPaymentList(data) {
  return instance.post("pay/adminPaymentList", data);
}
function getMembershipInfo(data) {
  return instance.post("pay/membershipInfo", data);
}
function postPay(data) {
  return instance.post(`pay`, data);
}
function putPay(data) {
  return instance.put(`pay`, data);
}
function refundPay(data) {
  return instance.put(`pay/refundPay`, data);
}
function paySave(data) {
  return instance.post("pay/paySave", data);
}
function adminPaySave(data) {
  return instance.post("pay/adminPaySave", data);
}
function paymentCheck(data) {
  return instance.post("pay/paymentCheck", data);
}

export {
  postPay,
  putPay,
  refundPay,
  paySave,
  paymentCheck,
  getPaymentList,
  getAdminPaymentList,
  adminPaySave,
  getMembershipInfo,
};
