import { instance } from "./index";

function getClient() {
  return instance.get(`client`);
}
function getClientByCompany(company) {
  return instance.get(`client/${company}`);
}
function getClientByCompanyStatus(company, status) {
  return instance.get(`client/${company}/?status=${status}`);
}
function getSelectClientList(company, status) {
  return instance.get(
    `client/list/${company}/selectClientList/?status=${status}`,
  );
}
function postManyClient(data) {
  return instance.post("client/excelupload", data);
}
function postClient(data) {
  return instance.post(`client`, data);
}
function putClient(data) {
  return instance.put(`client`, data);
}
function deleteClient(data) {
  return instance.delete(`client`, data);
}
export {
  getClient,
  getClientByCompany,
  getClientByCompanyStatus,
  postClient,
  putClient,
  postManyClient,
  deleteClient,
  getSelectClientList,
};
