import { instance } from "./index";

function getCompany() {
  return instance.get(`company`);
}
function getCompanyOneById(id) {
  return instance.get(`company/${id}`);
}

function postCompany(data) {
  return instance.post(`company`, data);
}
function putCompany(data) {
  return instance.put(`company`, data);
}
function deleteCompany(id) {
  return instance.delete(`company/${id}`);
}

export {
  getCompany,
  getCompanyOneById,
  postCompany,
  putCompany,
  deleteCompany,
  // getCompanyBusinessFind,
};
