export default [
  {
    value: 1,
    label: "주식회사",
  },
  {
    value: 2,
    label: "유한회사",
  },
  {
    value: 3,
    label: "합자회사",
  },
  {
    value: 4,
    label: "합명회사",
  },
  {
    value: 5,
    label: "유한책임회사",
  },
  {
    value: 6,
    label: "재단법인",
  },
  {
    value: 7,
    label: "사단법인",
  },
];
