export default [
  {
    value: "finished",
    label: "완제품",
  },
  {
    value: "post",
    label: "후가공",
  },
  {
    value: "raw",
    label: "생지",
  },
];
