// auth
function saveAuthCompanyToCookie(value) {
  document.cookie = `til_auth_company=${value}`;
}
function getAuthCompanyFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)til_auth_company\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
}
function saveAuthIdToCookie(value) {
  document.cookie = `til_auth_id=${value}`;
}
function getAuthIdFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)til_auth_id\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
}
function saveAuthUuidToCookie(value) {
  document.cookie = `til_auth_uuid=${value}`;
}
function getAuthUuidFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)til_auth_uuid\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
}
function saveAuthTokenToCookie(value) {
  document.cookie = `til_auth_token=${value}`;
}
function getAuthTokenFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)til_auth_token\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
}
function saveAuthGradeToCookie(value) {
  document.cookie = `til_auth_grade=${value}`;
}
function getAuthGradeFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)til_auth_grade\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
}
function saveAuthNameToCookie(value) {
  document.cookie = `til_auth_name=${value}`;
}
function getAuthNameFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)til_auth_name\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
}
// history
function saveHistoryCompanyToCookie(value) {
  document.cookie = `til_history_company=${value}`;
}
function getHistoryCompanyFromCookie() {
  const temp = document.cookie.replace(
    /(?:(?:^|.*;\s*)til_history_company\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
  return temp
    ? document.cookie
        .replace(
          /(?:(?:^|.*;\s*)til_history_company\s*=\s*([^;]*).*$)|^.*$/,
          "$1",
        )
        .split(",")
    : "";
}
function saveHistoryUuidToCookie(value) {
  document.cookie = `til_history_uuid=${value}`;
}
function getHistoryUuidFromCookie() {
  const temp = document.cookie.replace(
    /(?:(?:^|.*;\s*)til_history_uuid\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
  return temp
    ? document.cookie
        .replace(/(?:(?:^|.*;\s*)til_history_uuid\s*=\s*([^;]*).*$)|^.*$/, "$1")
        .split(",")
    : "";
}
function saveHistoryTokenToCookie(value) {
  document.cookie = `til_history_token=${value}`;
}
function getHistoryTokenFromCookie() {
  const temp = document.cookie.replace(
    /(?:(?:^|.*;\s*)til_history_token\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
  return temp
    ? document.cookie
        .replace(
          /(?:(?:^|.*;\s*)til_history_token\s*=\s*([^;]*).*$)|^.*$/,
          "$1",
        )
        .split(",")
    : "";
}
function saveHistoryGradeToCookie(value) {
  document.cookie = `til_history_grade=${value}`;
}
function getHistoryGradeFromCookie() {
  const temp = document.cookie.replace(
    /(?:(?:^|.*;\s*)til_history_grade\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
  return temp
    ? document.cookie
        .replace(
          /(?:(?:^|.*;\s*)til_history_grade\s*=\s*([^;]*).*$)|^.*$/,
          "$1",
        )
        .split(",")
    : "";
}

function saveTriralCookie(value) {
  document.cookie = `til_isExpired=${value}`;
}
function getTriralCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)til_isExpired\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
}
// delete
function deleteCookie(value) {
  document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
export {
  saveAuthCompanyToCookie,
  saveAuthIdToCookie,
  saveAuthUuidToCookie,
  saveAuthTokenToCookie,
  saveAuthGradeToCookie,
  saveAuthNameToCookie,
  saveHistoryCompanyToCookie,
  saveHistoryUuidToCookie,
  saveHistoryTokenToCookie,
  saveHistoryGradeToCookie,
  getAuthCompanyFromCookie,
  getAuthIdFromCookie,
  getAuthUuidFromCookie,
  getAuthTokenFromCookie,
  getAuthGradeFromCookie,
  getAuthNameFromCookie,
  getHistoryCompanyFromCookie,
  getHistoryUuidFromCookie,
  getHistoryTokenFromCookie,
  getHistoryGradeFromCookie,
  getTriralCookie,
  deleteCookie,
  saveTriralCookie,
};
