// 세션 체크후 만료시 route 이동 아니면 토큰재발급
import store from "@/store";
import { check, refreshCheck } from "@/api/auth";
// import { assignSuperUser } from "@/api/user";
import { getAuthIdFromCookie } from "@/utils/cookies";
async function handleSessionCheck(to, next) {
  try {
    if (store.state.auth_company) await check();
  } catch (e) {
    const reloginError =
      "다른 곳에서 로그인 되었습니다. 로그인 화면으로 이동합니다.";
    if (e === reloginError) {
      alert(reloginError);
      return next("/login");
    }
    try {
      await handleTokenRefresh(to, next);
    } catch (refreshError) {
      const networkError =
        !refreshError || refreshError.message === "Network Error";
      if (to.path !== "/login" && networkError) {
        alert("서버와 연결이 종료되었습니다. 로그인페이지로 이동합니다.");
        next("/login");
      }

      if (to.path !== "/login" && !networkError) {
        alert("세션이 만료되었습니다. 로그인페이지로 이동합니다.");
        next("/login");
      } else {
        next();
      }
    }
  }
}
// refreshToken을 통해 재발급
async function handleTokenRefresh(to, next) {
  const id = getAuthIdFromCookie();
  if (id) {
    const userData = await refreshCheck({ id: id });
    const user = userData?.data.user;

    if (user?.token) {
      store.commit("setAuthCheck", user);
      store.dispatch("LOGIN", user);
      await store.dispatch("GET_COMPANY");
      next();
    } else {
      throw new Error("토큰 재발급 실패");
    }
  } else {
    throw new Error("리프레시 토큰 없음");
  }
}
// auth 체크 로직
async function handleAuthCheck(to, next) {
  const id = getAuthIdFromCookie();
  // if (to.path === "/admin" && to.meta.auth && id) {
  //   const isSuper = await assignSuperUser({ uuid: id });
  //   const resultData = isSuper.data;

  //   if (resultData.status) {
  //     await store.dispatch("LOGIN", resultData.data);
  //     await store.dispatch("GET_COMPANY");

  //     next();
  //   }
  // }
  if (!id) {
    next("/login");
    return;
  }

  const userData = await refreshCheck({ id: id });

  if (userData.data.errorMessage === "trialPeriodExpired") {
    store.commit("setIsExpired", true);
    next("/login");
    return;
  }

  const user = userData?.data.user;

  if (user?.token) {
    store.commit("setAuthCheck", user);
    store.dispatch("LOGIN", user);
    await store.dispatch("GET_COMPANY");
    next();
  } else {
    store.dispatch("LOGOUT");
    next("/login");
  }
}

export { handleSessionCheck, handleAuthCheck };
