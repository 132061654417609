import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import { handleAuthCheck, handleSessionCheck } from "@/utils/session";
Vue.use(VueRouter);

const routes = [
  // defult => main page
  {
    path: "/",
    // redirect: "/main",
    component: () => import("../components/pages/Into/intoPage.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/LoginPage"),
    // component: () => import("../components/pages/login/LoginForm.vue"),
  },
  {
    name: "deleteAccount",
    path: "/deleteAccount",
    component: () => import("../components/pages/setting/DeleteAccount.vue"),
    meta: { auth: true },
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("../components/pages/backOffice/backOffice.vue"),
    meta: { auth: true },
  },
  //Main page
  {
    path: "/main",
    component: () => import("../views/MainPage"),
    children: [
      //main page => hoem page
      {
        name: "main",
        path: "/main",
        component: () => import("../components/pages/main/HomeForm.vue"),
        // component: () => import("../components/pages/alph/alph.vue"),
        meta: { auth: true },
      },
      //order page(오더 페이지)
      {
        name: "order",
        path: "/order",
        component: () => import("../components/pages/order/index.vue"),
        meta: { auth: true },
        props: true,
      },
      //Production page(생산 페이지)
      {
        name: "production",
        path: "/production",
        component: () => import("../components/pages/production/index.vue"),
        meta: { auth: true },
      },
      //   purchasecalculate page (매입정산 페이지)
      {
        name: "purchasecalculate",
        path: "/purchase/calculate",
        component: () =>
          import(
            "../components/pages/purchase/calculate/PurchaseCalculateForm.vue"
          ),
        meta: { auth: true },
      },
      //   purchasedeadline page (매입마감 페이지)
      {
        name: "purchasedeadline",
        path: "/purchase/deadline",
        component: () =>
          import(
            "../components/pages/purchase/deadline/PurchaseDeadlineForm.vue"
          ),
        meta: { auth: true },
      },
      //salescalculate page (매출정산 페이지)
      {
        name: "salescalculate",
        path: "/sales/calculate",
        component: () =>
          import("../components/pages/sales/calculate/SalesCalculateForm.vue"),
        meta: { auth: true },
      },
      // salesdeadline page (매출마감 페이지)
      {
        name: "salesdeadline",
        path: "/sales/deadline",
        component: () =>
          import("../components/pages/sales/deadline/SalesDeadLineForm.vue"),
        meta: { auth: true },
      },
      // 매입 외상금
      {
        name: "purchaseVat",
        path: "/vat/purchase",
        component: () =>
          import("../components/pages/vat/purchase/purchaseVat.vue"),
        meta: { auth: true },
      },
      // 매출 외상금
      {
        name: "salesVat",
        path: "/vat/sales",
        component: () => import("../components/pages/vat/sales/salesVat.vue"),
        meta: { auth: true },
      },
      //claim Page (클레임 페이지)
      {
        name: "claim",
        path: "/claim/claim",
        component: () => import("../components/pages/claim/Claim.vue"),
        meta: { auth: true },
      },
      {
        name: "endClaim",
        path: "/claim/end",
        component: () => import("../components/pages/claim/EndClaim.vue"),
        meta: { auth: true },
      },
      //setting page (설정 페이지)
      {
        name: "setting",
        path: "/setting",
        component: () => import("../components/pages/setting/SettingForm.vue"),
        meta: { auth: true },
      },
      {
        name: "help",
        path: "/help",
        component: () => import("../components/pages/help/main.vue"),
      },
      {
        name: "stock",
        path: "/stock",
        component: () => import("../components/pages/stock/index.vue"),
        meta: { auth: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE.URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await handleSessionCheck(to, next);

  document.title = to.meta.auth
    ? `WondanMania - ${store.state.auth_company}`
    : `WondanMania(원단마니아)`;

  if (to.meta.auth && !store.getters.isLogin) {
    await handleAuthCheck(to, next);
  } else if (to.meta.auth && store.getters.isLogin) {
    next();
  } else if (to.meta.admin && !store.getters.isAdmin) {
    alert(`관리자 이상의 등급이 필요합니다.`);
    next(false);
  } else {
    next();
  }
});

export default router;
