import { instancePdf } from "./index";
function sendEmail(data) {
  return instancePdf.post(`print/sendMail`, data);
}

function certifiedEmail(data) {
  return instancePdf.post(
    `print/certifiedEmail/?type='emailCode'&&reciver=${data.reciver}&&title=${data.title}&&content=${data.content}`,
  );
}

export { sendEmail, certifiedEmail };
