export default [
  {
    header: "이름",
    key: "name",
    width: "20",
  },
  {
    header: "가격",
    key: "unit_price",
    width: "10",
  },
  {
    header: "단위(가격)",
    key: "currency_sign",
    width: "20",
  },
  {
    header: "단위(길이)",
    key: "unit_sign",
    width: "15",
  },
  {
    header: "검사포함 여부",
    key: "inspect_status",
    width: "15",
  },
  {
    header: "생산처",
    key: "production",
    width: "40",
  },
  {
    header: "생산처 Item No",
    key: "production_item",
    width: "20",
  },
  {
    header: "경사",
    key: "slope",
    width: "10",
  },
  {
    header: "위사",
    key: "oblique",
    width: "10",
  },
  {
    header: "혼용률",
    key: "variety",
    width: "10",
  },
  {
    header: "가용 폭 ",
    key: "width",
    width: "10",
  },
  {
    header: "전체 폭",
    key: "full_width",
    width: "10",
  },
  {
    header: "단위 (폭길이)",
    key: "unit_width",
    width: "15",
  },
  {
    header: "밀도",
    key: "density",
    width: "10",
  },
  {
    header: "중량",
    key: "weight",
    width: "10",
  },
  {
    header: "단위(무게)",
    key: "unit_weight",
    width: "20",
  },
  {
    header: "후가공",
    key: "post_processing",
    width: "10",
  },
  {
    header: "특이사항",
    key: "content",
    width: "10",
  },
  {
    header: "담당자",
    key: "liaison",
    width: "10",
  },
];
