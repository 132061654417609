export default [
  {
    value: "client",
    label: "발주처",
  },
  {
    value: "purchase",
    label: "매입처",
  },
  {
    value: "sales",
    label: "매출처",
  },
  {
    value: "warehouse",
    label: "입고처",
  },
  {
    value: "inspection",
    label: "검사소",
  },
];
