export default {
  order: "오더",
  purchasecalculate: "매입정산",
  production: "생산",
  manufacture: "가공의뢰서",
  release: "출고의뢰서",
  claim: "클레임",
  sales: "출고의뢰서",
  salesdeadline: "매출마감 취소 ",
  purchasedeadline: "매입마감 취소 ",
  salescalculate: "매출정산 ",
  salesSettlenemt: "매출정산",
  purchase: "매입정산",
};
