import { instance } from "./index";
function getaApprovalComId(company, id) {
  return instance.get(`approval/${company}/?id=${id}`);
}
function getaApprovalRouteComId(company, approvalId, route) {
  return instance.get(
    `approval/${company}/id/?approvalId=${approvalId}${
      route ? "&route=" + route : ""
    }`,
  );
}
function getApprovalCountByStatus(company, params, status) {
  const query = [];

  for (const [key, value] of Object.entries(params)) {
    query.push(`${key}=${value}`);
  }

  query.push(...status.map((s) => `status[]=${s}`));
  return instance.get(`approval/${company}/count?${query.join("&")}`);
}

function getApprovalDetailByStatus(company, params, status) {
  const query = [];
  for (const [key, value] of Object.entries(params)) {
    query.push(`${key}=${value}`);
  }

  query.push(...status.map((s) => `status[]=${s}`));

  return instance.get(`approval/${company}/?${query.join("&")}`);
}

function getApprovalByStatus(company, params, status) {
  const query = [];
  for (const [key, value] of Object.entries(params)) {
    query.push(`${key}=${value}`);
  }

  query.push(...status.map((s) => `status=${s}`));
  return instance.get(`approval/${company}/?${query.join("&")}`);
}

function getDetailApproval(company) {
  return instance.get(`approval/${company}/detail`);
}
function getDetailApprovalKindStauts(company, status, kind) {
  return instance.get(
    `approval/${company}/detail?status=${status}&name=${kind}`,
  );
}
function getDetailApprovalKind(company, kind) {
  return instance.get(`approval/${company}/detail?name=${kind}`);
}
function getApproval(company) {
  return instance.get(`approval/${company}`);
}
function getRequestApproval(company, liaison, grade, type) {
  return instance.get(
    `approval/${company}/request/?liaison=${liaison}&grade=${grade}&type=${type}`,
  );
}
function getRequesApprovalTypeList(data) {
  return instance.post(`approval/requestTypeList`, data);
}
function getApprovalNameId(company, data) {
  return instance.get(
    `approval/${company}/detail?name=${data.name}&id=${data.id}`,
  );
}
function getRequestFormData(data) {
  return instance.post(`approval/requestForm`, data);
}
function getApprovalHistory(data) {
  return instance.post(`approval/getModifyHistory`, data);
}
function postApproval(data) {
  return instance.post(`/approval`, data);
}
function putApproval(data) {
  return instance.put(`/approval`, data);
}
function tempConfirm(data) {
  return instance.put(`/approval/confirm`, data);
}
function tempCompanion(data) {
  return instance.put(`/approval/companion`, data);
}

// 버전기록을 확인하기 위해 각 요청 문서의 doc Data 를 검색하는 API 호출
function getFindDocData(data) {
  return instance.post("approval/findDocData", data);
}
export {
  getApprovalCountByStatus,
  getApprovalDetailByStatus,
  getApprovalByStatus,
  postApproval,
  getDetailApproval,
  getApproval,
  putApproval,
  getDetailApprovalKindStauts,
  getaApprovalComId,
  getDetailApprovalKind,
  getApprovalNameId,
  getaApprovalRouteComId,
  getRequestApproval,
  tempConfirm,
  tempCompanion,
  getRequestFormData,
  getRequesApprovalTypeList,
  getApprovalHistory,
  getFindDocData,
};
