export default [
  {
    value: 1,
    label: "발주처",
  },
  {
    value: 2,
    label: "매입처",
  },
  {
    value: 3,
    label: "매출처",
  },
  {
    value: 4,
    label: "검사소",
  },
  {
    value: 5,
    label: "입고처",
  },
];
