import { instance } from "./index";

function getReceivableByClient(company, client, year, month, kind) {
  return instance.get(
    `receivable/${company}/${client}/${year}/${month}/${kind}/findReceivable`,
  );
}

function getReceivable(data) {
  return instance.post(`receivable/${data.company}/`, data);
}

function postReceivable(data) {
  return instance.post(`receivable`, data);
}
function putReceivable(data) {
  return instance.put(`receivable`, data);
}
function deleteReceivable(data) {
  return instance.delete(`receivable`, data);
}

/**
 * 원단 마니아 웹 안정화 작업 쿼리 (24.03.11 ~)
 */
function receivableFilters(data) {
  return instance.post(`receivable/${data.company}/filters`, data.params);
}
// 필터 리스트 호출 쿼리
function receivableSearch(data) {
  return instance.post(`receivable/${data.company}/search`, data.params);
}
function receivableCount(data) {
  return instance.post(`receivable/${data.company}/totalCount`, data.params);
}

export {
  // getReceivableByCompany,
  getReceivableByClient,
  getReceivable,
  postReceivable,
  putReceivable,
  deleteReceivable,
  receivableFilters,
  receivableSearch,
  receivableCount,
};
