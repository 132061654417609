import { instance } from "./index";
function getUseStockList(company, type) {
  return instance.get(`/useStock/${company}`);
}
function saveUseStockLength(company) {
  return instance.get(`/useStock/${company}/list/listCount`);
}
function postUseStock(data) {
  return instance.post(`useStock`, data);
}
function getIdStockList(company, stock) {
  return instance.get(`/useStock/${company}/${stock}/useStockQunatity`);
}
function putUseStock(data) {
  return instance.put(`useStock`, data);
}
function deleteUseStock(company, _id) {
  return instance.delete(`useStock/${company}/${_id}`);
}
export {
  getUseStockList,
  getIdStockList,
  postUseStock,
  putUseStock,
  deleteUseStock,
  saveUseStockLength,
};
