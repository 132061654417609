import { instance } from "./index";
function getSettingbyCompany(company) {
  return instance.get(`companySetting/${company}`);
}
function postSetting(company) {
  return instance.post(`companySetting`, company);
}
function putSetting(company) {
  return instance.put(`companySetting`, company);
}
export { getSettingbyCompany, postSetting, putSetting };
