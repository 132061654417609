export default [
  {
    value: 1,
    label: "(주)",
  },
  {
    value: 2,
    label: "(유)",
  },
  {
    value: 3,
    label: "(합)",
  },
  {
    value: 4,
    label: "(합명)",
  },
  {
    value: 5,
    label: "(유)",
  },
  {
    value: 6,
    label: "(재)",
  },
  {
    value: 7,
    label: "(사)",
  },
];
