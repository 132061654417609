export default [
  {
    text: "마감",
    value: "mainDay",
    width: "2%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
    sort: -1,
  },
  {
    text: "구분",
    value: "type",
    width: "2%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
  },
  {
    text: "년도",
    value: "year",
    width: "2%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
    sort: -1,
  },
  {
    text: "시즌",
    value: "season",
    width: "2%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
  },
  {
    text: "오더",
    value: "order",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
    sort: -1,
  },
  {
    text: "담당자",
    value: "liaison",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
  },
  {
    text: "접수월",
    value: "day",
    width: "3%",
    align: "center",
    show: true,
    type: "date",
    dateType: "yymm",
    class: "borderHeaderEmpty",
    sort: -1,
  },
  {
    text: "발주처",
    value: "client",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
  },

  {
    text: "아이템",
    value: "item",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderEmpty",
  },

  {
    text: "매입아이템",
    value: "pur_item",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",
  },
  {
    text: "매입처",
    value: "pur_place",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",
  },

  {
    text: "월",
    value: "pur_month",
    width: "2%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",
    type: "date",
    dateType: "yymm",
    sort: -1,
    groupable: true,
  },

  {
    text: "발주 수량",
    value: "quantity",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",
    groupable: true,
    type: "number",
  },
  {
    text: "가공 수량",
    value: "manu_quantity",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "가공 단가",
    value: "manu_unitprice",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
  },

  {
    text: "검사 수량",
    value: "inspect_quantity",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },

  {
    text: "출고 수량",
    value: "rel_quantity",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "마감 수량",
    value: "pur_quantity",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "매입 단가",
    value: "pur_unit_price",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "매입 금액",
    value: "pur_price",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },

  {
    text: "월",
    value: "sale_month",
    width: "2%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",
    type: "date",
    dateType: "yymm",
    groupable: true,
    sort: -1,
  },
  {
    text: "매출처",
    value: "sale_place",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",
  },
  {
    text: "수량",
    value: "sale_quantity",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "단가",
    value: "sale_unit_price",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "금액",
    value: "sale_price",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "이익",
    value: "salesProfit",
    width: "5%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
  {
    text: "마진",
    value: "margin",
    width: "3%",
    align: "center",
    show: true,
    class: "borderHeaderLeft",

    groupable: true,
    type: "number",
  },
];
