export default {
  "00": "마감",
  10: "삭제 요청",
  20: "승인 요청",
  30: "승인 반려",
  40: "승인 확인",
  50: "수정 승인 요청",
  60: "마감 요청",
  70: "생성",
  90: "취소 요청",
  99: "삭제",
};
