import { instance } from "./index";

function getItem() {
  return instance.get(`item`);
}
function getItemByCompany(company) {
  return instance.get(`item/${company}`);
}
function getItemByCompanybyStatus(company, status) {
  return instance.get(`item/${company}/?status=${status}`);
}
function getItemByCompanyP(company) {
  return instance.get(`item/${company}/p`);
}
function postItem(data) {
  return instance.post(`item`, data);
}
function postManyItem(data) {
  return instance.post("item/excelupload", data);
}
function putItem(data) {
  return instance.put(`item`, data);
}
function deleteItem(name) {
  return instance.delete(`item/${name}`);
}

export {
  getItem,
  getItemByCompany,
  getItemByCompanyP,
  getItemByCompanybyStatus,
  postItem,
  putItem,
  deleteItem,
  postManyItem,
};
