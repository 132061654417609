export default [
  {
    text: "오더",
    value: "order",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    sort: -1,
  },
  {
    text: "담당자",
    width: "5%",
    value: "liaison",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: " ",
    width: "2%",
    align: "center",
    class: "tableHeaderBody",
    value: "arise_checkbox",
    filterable: false,
  },
  {
    text: "월",
    value: "arise_month",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
    type: "date",
    dateType: "yyyymm",
    sort: -1,
  },
  {
    text: "구분",
    value: "arise_type",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "업체명",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    value: "arise_client",
  },
  {
    text: "금액",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "arise_price",
    type: "number",
    groupable: true,
  },

  {
    text: "전가",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "arise_passtype",
    filterable: true,
  },
  {
    text: "내용",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "arise_detail",
    filterable: false,
  },
  {
    text: "상태",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    value: "arise_status",
    filterable: true,
  },
  {
    text: "수정",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
    value: "arise_modify",
    filterable: false,
  },
  {
    text: " ",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    value: "ariseNone",
    filterable: false,
  },

  {
    text: " ",
    width: "2%",
    align: "center",
    class: "tableHeaderBody",
    value: "pass_checkbox",
    filterable: false,
  },
  {
    text: "월",
    value: "pass_month",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
    type: "date",
    dateType: "yyyymm",
    sort: -1,
  },

  {
    text: "구분",
    value: "pass_type",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "업체명",
    width: "6%",
    align: "center",
    class: "tableHeaderBody",
    value: "pass_client",
  },
  {
    text: "금액",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "pass_price",
    type: "number",
    groupable: true,
  },

  {
    text: "내용",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "pass_detail",
    filterable: false,
  },
  {
    text: "상태",
    width: "3%",
    align: "center",
    class: "tableHeaderBody",
    value: "pass_status",
    filterable: true,
  },
  {
    text: "수정",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
    value: "pass_modify",
    filterable: false,
  },
  {
    text: " ",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
    value: "passNone",
    filterable: false,
  },
];
