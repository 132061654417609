import { instanceAuth, instanceAuthToken, instance } from "./index";

function register(data) {
  return instanceAuth.post("register", data);
}

function companyRegister(data) {
  return instanceAuth.post("companyRegister", data);
}

function login(data) {
  return instanceAuth.post("login", data);
}

function check(data) {
  return instanceAuthToken.get("check", data);
}
function adminCheck(data) {
  return instanceAuth.post("checkAdmin", data);
}

//회원 가입시 uuid 중복 체크
function idCheck(id) {
  return instanceAuth.get(`idCheck/?uuid=${id}`);
}

//회원 가입시 회사 사업자번호 검색
function companyCheck(data) {
  return instanceAuth.post(`businessFind`, data);
}

//비밀번호 변경
function modifyPw(data) {
  return instanceAuth.post(`pwModify`, data);
}

// 인증번호 발송
function verificationCode(data) {
  return instanceAuth.post(
    `sendVerificationCode/?type='emailCode'&&reciver=${data.reciver}&&title=${data.title}&&content=${data.content}`,
  );
}
// 문자 인증번호 발송
function authCodeSend(data) {
  return instanceAuth.post("/authcodeSend", data);
}
function refreshCheck(data) {
  return instanceAuth.post("refreshCheck", data);
}
//회사 만료기간 체크
function checkExpired(data) {
  return instanceAuth.post("checkExpired", data);
}
export {
  register,
  login,
  check,
  idCheck,
  companyCheck,
  companyRegister,
  modifyPw,
  refreshCheck,
  verificationCode,
  adminCheck,
  checkExpired,
  authCodeSend,
};
