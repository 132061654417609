import store from "@/store";
import router from "@/router";

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function (config) {
      config.headers["x-access-token"] = store.state.auth_token;
      config.headers["uuid"] = store.state.auth_uuid;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      let errorMessage = "";
      if (error.response.data.message === "ReLogin") {
        errorMessage =
          "다른 곳에서 로그인 되었습니다. 로그인 화면으로 이동합니다.";
        store.dispatch("LOGOUT");
        router.push({
          name: "login",
        });
      }

      if (error.response.data === "ValidationError")
        errorMessage = "데이터값이 부족합니다.";
      if (error.response.data === "VersionError")
        errorMessage = "현재 데이터와 입력값이 다릅니다.";
      if (error.response.data === "DisconnectedError")
        errorMessage = "연결시간이 초과 되었습니다.";

      return Promise.reject(errorMessage);
    },
  );

  return instance;
}
