export default [
  {
    value: 1,
    label: "현 금",
  },
  {
    value: 2,
    label: "어 음",
  },
  {
    value: 3,
    label: "전자채권",
  },
  {
    value: 4,
    label: "외 환",
  },
  {
    value: 5,
    label: "선수금",
  },
  {
    value: 6,
    label: "기 타",
  },
];
