import { instance } from "./index";

function getfile() {
  return instance.get(`file`);
}
function getfileOneById(company, id) {
  return instance.get(`file/${company}/${id}`);
}
function getfileByIdAndName(company, id, name) {
  return instance.get(`file/${company}/${id}/${name}/overlabCheck`);
}
function appDownload(type) {
  return instance.get(`file/appDownload/${type}`, {
    responseType: "arraybuffer",
  });
}
// function getLogoFile(company) {
//   console.log(company);
//   return instance.get(`file/${company}/${company}/logoDownload`, {
//     responseType: "arraybuffer",
//   });
// }

function downloadFile(company, id) {
  return instance.get(`file/${company}/${id}/fileDownload`, {
    responseType: "arraybuffer",
  });
}
function downloadFileNamed(company, name) {
  return instance.post(
    `file/fileDownload`,
    { company, name },
    {
      responseType: "arraybuffer",
    },
  );
}

function postfile(data) {
  return instance.post(`file`, data);
}
function putfile(data) {
  return instance.put(`file`, data);
}
function deletefile(id) {
  return instance.delete(`file/${id}`);
}

export {
  getfile,
  getfileOneById,
  postfile,
  putfile,
  downloadFile,
  deletefile,
  appDownload,
  getfileByIdAndName,
  downloadFileNamed,
  // getLogoFile,
};
