export default [
  {
    text: "년도",
    width: "3%",
    value: "year",
    align: "center",
    class: "tableHeaderBody",
    filterable: true,
    sort: -1,
  },
  {
    text: "월",
    width: "3%",
    value: "month",
    align: "center",
    class: "tableHeaderBody",
    type: "date",
    dateType: "m",
    filterable: true,
    sort: -1,
  },
  {
    text: "업체명(매입처)",
    align: "center",
    width: "8%",
    value: "name",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "계산서 발행처",
    align: "center",
    width: "8%",
    value: "bill_client",
    class: "tableHeaderBody",
    filterable: true,
  },

  {
    text: "마감 금액",
    align: "center",
    width: "8%",
    value: "amount",
    class: "tableHeaderBody",
    type: "number",
    filterable: true,
  },

  {
    text: "세액",
    align: "center",
    width: "8%",
    value: "taxAmount",
    class: "tableHeaderBody",
    type: "number",
    filterable: true,
  },

  {
    text: "합계 금액",
    align: "center",
    width: "8%",
    value: "vat_amount",
    class: "tableHeaderBody",
    type: "number",
    filterable: true,
  },
  {
    text: "지급액",
    align: "center",
    width: "8%",
    value: "deposit_amount",
    class: "tableHeaderBody",
    type: "number",
    filterable: true,
  },
  {
    text: "외화 지급액",
    align: "center",
    width: "8%",
    value: "original_deposit_amount",
    class: "tableHeaderBody",
    type: "number",
    filterable: true,
  },
  {
    text: "마지막 지급날짜",
    align: "center",
    width: "5%",
    value: "deposit_date",
    class: "tableHeaderBody",
    type: "date",
    dateType: "yyyymm",
    filterable: true,
    sort: -1,
  },
  {
    text: "마지막 지급형태",
    align: "center",
    width: "5%",
    value: "type",
    class: "tableHeaderBody",
    filterable: true,
  },
  {
    text: "마지막 만기일",
    align: "center",
    width: "5%",
    value: "expiration",
    class: "tableHeaderBody",
    type: "date",
    dateType: "yyyymm",
    filterable: true,
    sort: -1,
  },
  {
    text: "비고",
    align: "center",
    width: "8%",
    value: "memo",
    class: "tableHeaderBody",
    filterable: false,
  },
  {
    text: "잔액",
    align: "center",
    width: "8%",
    class: "tableHeaderBody",
    value: "balance",
    groupable: true,
    type: "number",
    filterable: true,
  },
  {
    text: "",
    align: "center",
    width: "5%",
    class: "tableHeaderBody",
    value: "btn",
    type: "btn",
    filterable: false,
  },
];
