import { instance } from "./index";

function getClaim(company) {
  return instance.get(`claim/${company}`);
}
function claimListFind(data) {
  return instance.post(`claim/${data.company}/claimList`, data);
}
function getClaimListStatus(company, params, status) {
  const query = [];
  for (const [key, value] of Object.entries(params)) {
    query.push(`${key}=${value}`);
  }

  return instance.get(`claim/${company}/?${query.join("&")}`);
}
function endClaimListFind(data) {
  return instance.post(`claim/${data.company}/endClaimList`, data);
}

function putClaim(data) {
  return instance.put(`claim`, data);
}
function postClaim(data) {
  return instance.post(`claim`, data);
}
function deleteClaim(company, id) {
  return instance.delete(`claim/${company}/${id}`);
}

function targetClaimFind(company, _id) {
  return instance.get(`claim/${company}/?_id=${_id}`);
}
function notParseClaimFInd(company, id) {
  return instance.get(`claim/${company}/?id=${id}`);
}
function claimIdFind(company, id) {
  return instance.get(`claim/${company}/${id}`);
}
function claimTypeIdFind(company, id) {
  const searchKey = id.split("_")[1];
  const SearchValue = `${searchKey}_id=${id}`;
  return instance.get(`claim/${company}/?${SearchValue}`);
}
function saveClaim(form) {
  return instance.post("claim/claimSave", form);
}
function claimMultiSave(form) {
  return instance.post("claim/claimMultiSave", form);
}

/**
 * 원단 마니아 웹 안정화 작업 쿼리 (24.03.11 ~)
 */
function claimFilters(data) {
  return instance.post(`claim/${data.company}/filters`, data.params);
}
// 필터 리스트 호출 쿼리
function claimSearch(data) {
  return instance.post(`claim/${data.company}/search`, data.params);
}
function claimTotalCount(data) {
  return instance.post(`claim/${data.company}/totalCount`, data.params);
}
export {
  getClaim,
  putClaim,
  postClaim,
  deleteClaim,
  targetClaimFind,
  claimIdFind,
  claimTypeIdFind,
  claimListFind,
  endClaimListFind,
  notParseClaimFInd,
  getClaimListStatus,
  saveClaim,
  claimMultiSave,
  // 필터 리스트 호출 쿼리
  claimFilters,
  claimSearch,
  claimTotalCount,
};
