import { instance } from "./index";

function productionPageSave(param) {
  return instance.post(`production/productionPageSave`, param);
}
function productionPageDelete(param) {
  return instance.post(`production/productionPageDelete`, param);
}
function productionPageSearchApproval(param) {
  return instance.post("production/productionPageSearchApproval", param);
}

function productionPageNewDocDelete(param) {
  return instance.post("production/productionPageNewDocDelete", param);
}

export {
  productionPageSave,
  productionPageDelete,
  productionPageSearchApproval,
  productionPageNewDocDelete,
};
