import { instance } from "./index";

function getReceivableAmount(company, kind) {
  return instance.get(`receiveAmount/${company}`);
}

function postReceivableAmount(data) {
  return instance.post(`receiveAmount`, data);
}
function saveReceivableAmount(data) {
  return instance.post(`receiveAmount/receiveAmountSave`, data);
}
function putReceivableAmount(data) {
  return instance.put(`receiveAmount`, data);
}
function deleteReceivableAmount(company, id, kind) {
  return instance.delete(`receiveAmount/${company}/${id}/${kind}`);
}
function deleteReceivableAmountbyId(company, _id) {
  return instance.delete(`receiveAmount/${company}/${_id}`);
}

export {
  // getReceivableByCompany,
  saveReceivableAmount,
  getReceivableAmount,
  postReceivableAmount,
  putReceivableAmount,
  deleteReceivableAmount,
  deleteReceivableAmountbyId,
};
