import { instance } from "./index";

function getStockForRelease(company, release) {
  return instance.get(`/stock/${company}/${release}`);
}
function getStockForId(company, id) {
  return instance.get(`/stock/${company}/${id}/stockId`);
}
// function getStockForItem(company, item) {
//   return instance.get(`/stock/${company}/${item}/stockItem`);
// }

function getStockList(company) {
  return instance.get(`/stock/${company}`);
}
function getStockTableList(data) {
  return instance.post(`/stock/${data.company}/list/stockList`, data);
}
function getUseStockClientList(data) {
  return instance.post(`/stock/useStockClientList`, data);
}
function getSetStockList(company, client) {
  return instance.get(`/stock/${company}/list/setStockList/${client}`);
}
function postStock(data) {
  return instance.post(`stock`, data);
}

function putStock(data) {
  return instance.put(`stock`, data);
}
function saveStockId(company) {
  return instance.get(`/stock/${company}/list/listCount`);
}
function deleteStock(company, id) {
  return instance.delete(`stock/${company}/${id}`);
}
function saveStock(data) {
  return instance.post(`stock/saveStock`, data);
}
function saveSelectStock(data) {
  return instance.post(`stock/saveSelectStock`, data);
}
/**
 * 원단 마니아 웹 안정화 작업 쿼리 (24.03.11 ~)
 */
function stockFilters(data) {
  return instance.post(`stock/${data.company}/filters`, data.params);
}
// 필터 리스트 호출 쿼리
function stockSearch(data) {
  return instance.post(`stock/${data.company}/search`, data.params);
}
function stockCount(data) {
  return instance.post(`stock/${data.company}/totalCount`, data.params);
}

export {
  getStockForRelease,
  getStockList,
  postStock,
  putStock,
  getStockForId,
  deleteStock,
  // getStockForItem,
  getStockTableList,
  saveStockId,
  getSetStockList,
  stockFilters,
  stockSearch,
  stockCount,
  saveStock,
  saveSelectStock,
  getUseStockClientList,
};
