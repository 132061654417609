export default [
  {
    header: "구분",
    key: "company_type",
    width: "40",
  },
  {
    header: "회사명 구분",
    key: "type_postion",
    width: "30",
  },
  {
    header: "지점",
    key: "zone",
    width: "10",
  },
  {
    header: "회사명",
    key: "name_only",
    width: "10",
  },
  {
    header: "계산서 발행처",
    key: "bill_client",
    width: "10",
  },
  {
    header: "대표명",
    key: "representative",
    width: "10",
  },
  {
    header: "사업자번호",
    key: "number",
    width: "10",
  },
  {
    header: "업종",
    key: "industry",
    width: "10",
  },
  {
    header: "전화번호",
    key: "tel",
    width: "10",
  },
  {
    header: "팩스번호",
    key: "fax",
    width: "20",
  },
  {
    header: "이메일",
    key: "email",
    width: "10",
  },
  {
    header: "주소",
    key: "address",
    width: "25",
  },
  {
    header: "연락담당자",
    key: "liaison",
    width: "10",
  },
];
